a {
  color: white;
  text-decoration: none;
}

.navigation {
  overflow: hidden;
  display: flex;
  color: white;
  align-items: center;
  background-color: ghostwhite;
  /* background-color: #44cd5c; */
  padding: 12px;
}
.brand {
  display: flex;
  align-items: center;
}
.logout {
  cursor: pointer;
}

.logo {
  width: 65%;
}
