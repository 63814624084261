h4 {
  font-size: 20px;
  color: #4b4d67;
  font-family: 'Montserrat', sans-serif;
}
.SvgIcon {
  font-size: 24px;
}
.createInputLabel {
  font-size: 14px;
  color: #bdbdbd;
}
