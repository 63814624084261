*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}
input {
  -webkit-user-select: text; /* Chrome, Opera, Safari */
  -moz-user-select: text; /* Firefox 2+ */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Standard syntax */
}
.slick-track {
  float: left;
}
.table {
  margin-bottom: 0;
}
/* width */
.customScrollBar::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.customScrollBar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.customScrollBar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.customScrollBar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.profileBgColor {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: default;
}
.column-gap-20 {
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
    animation: App-logo-spin infinite 20s linear;
  }
}

/*Radio button*/
@import url('https://fonts.googleapis.com/css?family=Lato');

body,
html {
  height: 100%;
  background: #222222;
  font-family: 'Lato', sans-serif;
}

h2 {
  color: #aaaaaa;
}

.custom-radioBtn ul {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.custom-radioBtn ul li {
  color: #aaaaaa;
  display: block;
  position: relative;
  /*float: left;*/
  width: 100%;
  /*height: 100px;*/
}

.custom-radioBtn ul li input[type='radio'] {
  position: absolute;
  visibility: hidden;
}

.custom-radioBtn ul li label {
  display: block;
  position: relative;
  padding: 0 0 0 30px;
  margin-bottom: 0;
  height: auto;
  z-index: 1;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #3c3f41;
}
.custom-radioBtn ul li .check {
  display: block;
  position: absolute;
  border: 3px solid #aaaaaa;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  top: 5px;
  left: 0;
  z-index: 1;
  -o-transition: border 0.25s linear;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

.custom-radioBtn ul li:hover .check {
  border: 3px solid #49d360;
}

.custom-radioBtn ul li .check::before {
  display: block;
  position: absolute;
  content: '';
  border-radius: 100%;
  height: 10px;
  width: 10px;
  top: 2.2px;
  left: 2px;
  margin: auto;
  -o-transition: background 0.25s linear;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

.custom-radioBtn input[type='radio']:checked ~ .check {
  border: 3px solid #49d360;
}

.custom-radioBtn input[type='radio']:checked ~ .check::before {
  background: #49d360;
}

.custom-radioBtn input[type='radio']:checked ~ label {
  color: #3c3f41;
}

.green-text {
  color: #49d360;
}
.border-danger {
  color: #dc3545 !important;
}
.searchLoader {
  position: absolute;
  top: 12px;
  left: 10px;
}
.image-gallery-image {
  width: 100%;
  height: 200px;
}

.image-gallery-thumbnail-image {
  width: 100%;
  height: 40px;
}

.image-gallery-thumbnail {
  width: 48px;
}

.image-gallery-icon .image-gallery-fullscreen-button {
  display: none;
  padding: 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.column-gap-10 {
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
}
.App-link {
  color: #61dafb;
}

/*castingpaxLanding*/
.castingpaxLanding header {
  height: auto;
  background-color: transparent;
}
.castingpaxLanding header h1 {
  font-weight: bold;
  font-size: 24px;
  color: #000;
  margin-bottom: 0;
}

header h1 > span {
  display: inline-block;
  font-weight: bold;
  color: #49d360;
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
body {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: white;
}
.web-display-only {
  display: block;
}
.mobile-display-only {
  display: none;
}
span {
  display: inline-block;
  margin-bottom: 10px;
}
.navbar-light {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  -webkit-box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #f2f2f5;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}
.custom-control {
  z-index: auto;
}
.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #f2f2f5;
}

/*custom-style*/
.spinner-border-sm {
  width: 15px;
  height: 15px;
  border-width: 0.2em;
  margin: 0 5px 0 0;
  padding: 0;
}
.primary {
  background-color: #49d360 !important;
}
.secondary {
  background-color: #4b4d67 !important;
}
.mt-15 {
  margin-top: 15px;
}
.mt-12 {
  margin-top: 12px;
}

.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mb-30 {
  margin-bottom: 30px;
}
.pt-40 {
  padding-top: 40px !important;
}
.pb-30 {
  padding-bottom: 30px;
}
.btn {
  border-radius: 10px;
}
.btn-outline-success:hover {
  color: #fff !important;
  border-color: #28a745 !important;
  background-color: #28a745 !important;
}
.line-height-normal {
  line-height: normal;
}
a:focus,
button:focus,
[role='button']:focus {
  outline: none !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker .react-datepicker__navigation {
  background-color: transparent;
}
.input-datePicker {
  position: absolute !important;
  top: -7px !important;
  background-color: #fff !important;
  font-size: 14px;
  color: #49d360 !important;
  width: 45%;
  text-align: center;
}
.profileCreation span {
  color: #49d360;
  font-size: 14px;
}
.profileCreation .css-yk16xz-control {
  min-height: 48px;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  font-size: 14px;
}
.profileCreation .css-1pahdxg-control {
  height: 48px !important;
  min-height: 48px !important;
  border-color: #49d360 !important;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 10px !important;
}
.profileCreation .PhoneInputCountry {
  border: 1px solid #e2e2ea;
  border-right: 0;
  width: 50px;
  margin-right: 0;
  text-align: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #fff;
}
.profileCreation .PhoneInputCountryIcon {
  margin-left: 6px;
}
.profileCreation .PhoneInputInput {
  height: 48px;
  border: 1px solid #e2e2ea;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.profileCreation .PhoneInputInput:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.profileCreation .PhoneInputCountrySelectArrow {
  color: #49d360 !important;
}
.profileCreation .css-yk16xz-control:hover,
.profileCreation .css-yk16xz-control:active {
  border-color: #e2e2ea;
}
.profileCreation .css-yk16xz-control:focus {
  border-color: #49d360;
}
.profileCreation .css-1uccc91-singleValue {
  font-size: 14px;
  font-weight: 400;
}
.profileCreation .css-1okebmr-indicatorSeparator {
  display: none;
}
.profileCreation .css-tj5bde-Svg {
  color: #000;
  font-size: 14px;
  width: 16px;
}
.profileCreation .editProfile .PhoneInputInput {
  font-size: 14px;
}
/*buttons*/
.sizeCard-btn {
  width: 136px;
}
.sizeCard-btn svg {
  width: 20px;
  height: 20px;
}
.addNewImage svg {
  height: 18px;
}
.addNewImage {
  font-weight: 600;
  font-size: 15px;
  width: 200px;
  height: 50px;
  line-height: 15px;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.fontFourteen {
  font-size: 14px !important;
}
.fontTwenty {
  font-size: 20px !important;
}
.fontTwelve {
  font-size: 12px;
}
.slick-slide {
  position: relative;
}
.main-top-slider .slick-slide .selected-item:before {
  content: '';
  position: absolute;
  border-top: 15px solid #4b4d67;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  height: 0;
  width: 0;
  bottom: -15px;
  z-index: 4;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.main-top-slider .slick-list {
  height: 120px;
}
.main-top-slider .slick-track {
  top: 30px;
}
.episodeItemWrapper .slick-track {
  top: 0;
  width: 100% !important;
}

/*topHomeTabs*/

.topHomeTabs .nav-tabs {
  border-bottom: 0;
}
.topHomeTabs .nav-tabs a {
  margin: 0 15px;
  position: relative;
  display: inline-block;
  border: none;
  border-radius: 0;
}
.topHomeTabs .nav-tabs span {
  font-size: 16px;
  color: #828282;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}

.topHomeTabs .nav-tabs span img {
  display: inline-block;
  margin-right: 5px;
}
.topHomeTabs .nav-tabs .nav-link.active span {
  color: #000;
}
.topHomeTabs .nav-tabs .nav-link.active svg {
  color: #49d360;
}
.topHomeTabs .nav-item.nav-link::before {
  content: '';
  position: absolute;
  bottom: -32px;
  width: 100%;
  height: 3px;
  background-color: transparent;
}
.topHomeTabs .nav-item.nav-link.active::before {
  content: '';
  position: absolute;
  bottom: -15px;
  width: 100%;
  height: 3px;
  left: 0;
  background-color: #49d360;
}
/*topBarCalendarWrapper*/
.slick-slider {
  width: 100%;
}
.topBarCalendarWrapper .slick-slider {
  padding: 0 27px;
  height: 100px;
}
.topBarCalendarWrapper .slick-slider .slick-list {
  height: 100%;
}
.topBarCalendarWrapper .prev-arrow {
  left: 15px !important;
}
.topBarCalendarWrapper .next-arrow {
  right: 15px !important;
}

/*find performer performerSearchWrapper*/
.performerSearchWrapper span {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.performerSearchWrapper .css-1wa3eu0-placeholder {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #bdbdbd;
}
.performerSearchWrapper .css-yk16xz-control {
  -webkit-box-shadow: 4px 4px 10px rgba(69, 69, 69, 0.2);
  box-shadow: 4px 4px 10px rgba(69, 69, 69, 0.2);
  border-radius: 8px;
  border: 0;
  min-height: 40px !important;
}
.performerSearchWrapper .css-g1d714-ValueContainer {
  padding: 4px 8px;
  font-size: 14px;
}
.performerSearchWrapper .css-1pahdxg-control {
  border: 0 !important;
  border-color: transparent !important;
  min-height: 40px !important;
  -webkit-box-shadow: 4px 4px 10px rgba(69, 69, 69, 0.2);
  box-shadow: 4px 4px 10px rgba(69, 69, 69, 0.2);
}

.performerSearchWrapper .css-1pahgxd-control:active,
.performerSearchWrapper .css-1pahgxd-control:focus {
  outline: none;
  border-color: transparent;
  border: 0;
}

.performerSearchWrapper .css-1uccc91-singleValue {
  font-size: 14px;
  color: #bdbdbd;
  font-weight: normal;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.1em;
}
.performerSearchWrapper .css-1okebmr-indicatorSeparator {
  display: none;
}
.performerSearchWrapper .css-tj5bde-Svg {
  font-size: 14px;
  color: #000;
  width: 18px;
}
.ck.ck-dropdown .ck-button.ck-dropdown__button {
  position: relative;
  z-index: 0;
}
.searchSectionWrapper span {
  display: none;
}
.searchSectionWrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  border-left: 1px solid #e0e0e0;
  padding-left: 15px;
}
.searchSectionWrapper .form-group {
  margin-bottom: 0 !important;
  width: 100% !important;
}
.searchSectionWrapper .css-yk16xz-control {
  height: 60px;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.searchSectionWrapper .css-1pahdxg-control,
.searchSectionWrapper .css-1pahdxg-control:active,
.searchSectionWrapper .css-1pahdxg-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border-radius: 0;
}
.searchSectionWrapper .css-g1d714-ValueContainer {
  height: 60px;
}
.css-2613qy-menu {
  position: relative;
  z-index: 3;
}
.select-box-mt {
  margin-top: -33px;
}
/*popover*/
.popover-body {
  padding: 0;
}

.popover {
  max-width: 350px;
  border: 0;
  border-radius: 10px;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.popover .arrow::before,
.popover .arrow::before {
  border-right-color: transparent;
}
.popover.skillsPopoverWidth {
  max-width: 28.5%;
  width: 100%;
}
.fullProfileDetailsWrapper {
  position: relative;
  top: -30px;
}
.fullProfileDetailsWrapper .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #fff;
  color: #4b4d67;
  border: 1px solid #f2f2f2;
  border-bottom: 0;
  font-size: 13px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}
.expandedSearchbarWrapper .searchSectionWrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 12%;
  flex: 0 0 12%;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
/*requestTableWrapper*/
.table-layout-fixed {
  table-layout: fixed;
}
.table-responsive.requestTableWrapper {
  max-height: 425px;
  overflow-x: auto;
}
.requestTableWrapper .table,
.requestTableWrapperMobile .table {
  table-layout: fixed;
}
.requestTableWrapper .table td,
.requestTableWrapper .table th,
.requestTableWrapperMobile .table td,
.requestTableWrapperMobile .table th {
  border: 0;
}
.requestTableWrapper .table tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #f2f2f2;
}
.requestTableWrapper .table tr td,
.requestTableWrapperMobile .table tr td {
  padding-top: 10px;
  padding-bottom: 10px;
}
.requestTableWrapper .table tr:last-child {
  border-bottom: none;
}
.requestTableWrapper .table td {
  padding: 0;
}
.requestTableWrapper .table td:first-child {
  width: 38%;
}
.requestTableWrapper .table td:nth-child(2) {
  width: 17%;
}
.requestTableWrapper .table td:nth-child(3) {
  width: 15%;
}
.requestTableWrapper .table td:last-child {
  width: 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.requestTableWrapper .table button {
  width: 90px;
  height: 30px;
  line-height: 30px;
  padding: 0;
  text-align: center;
}
.noLabel {
  opacity: 0;
}
.popUp {
  z-index: 10;
  position: absolute;
}
/**/
.rbc-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  padding: 15px 30px;
}

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
  padding-top: 0px;
  margin-bottom: 0;
}
.rbc-toolbar .rbc-toolbar-label {
  text-align: left !important;
  margin-bottom: 0;
  padding: 0 !important;
}
.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
  padding-top: 0px;
  margin-bottom: 0;
}
.rbc-calendar {
  height: 340px;
  max-height: 340px;
  padding: 0 25px;
}
.rbc-day-bg + .rbc-day-bg {
  border: 0 !important;
  border-radius: 0;
}
.rbc-month-row + .rbc-month-row {
  max-height: 50px;
}
.rbc-month-view,
.rbc-header + .rbc-header,
.rbc-header {
  border: 0 !important;
  border-bottom: 0 !important;
  border-left: 0 !important;
}
.rbc-header span {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #252427;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
}
.rbc-row-content {
  z-index: 1 !important;
}
.rbc-toolbar .rbc-btn-group button {
  color: #373a3c !important;
}
.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
  margin-right: 10px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 30px !important;
  margin-right: 10px;
  position: relative;
}
.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}
/*calndarWrapperPage*/
.calendarWrapperPage {
  padding: 30px;
}
/**/
.table-responsive.callTableWrapper {
  max-height: 370px;
  overflow-y: auto;
}
.callTableWrapper {
  padding-left: 10px;
  padding-right: 10px;
}
.callTableWrapper .table tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #f2f2f2;
}
.callTableWrapper .table tr:last-child {
  border-bottom: 0;
}
.callTableWrapper .table td {
  padding: 10px 0;
}
.rbc-row-content {
  max-height: 50px;
}
.rbc-month-row + .rbc-month-row {
  border-top: 0 !important;
}
.rbc-month-row {
  overflow: initial !important;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
}
.rbc-date-cell {
  min-width: 0;
  padding-right: 0 !important;
  text-align: center !important;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-preferred-size: calc(100% / 7) !important;
  flex-basis: calc(100% / 7) !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
}
.rbc-row-content .rbc-row:nth-child(n + 2) {
  position: absolute;
  top: 0;
  width: 100%;
}
.rbc-selected,
.popover-height {
  height: 50px;
  text-align: center;
  line-height: 50px;
}
.rbc-event-content {
  background-color: #f2f2f2;
  border-radius: 50%;
  width: 50px;
  margin: 0 auto;
}
.rbc-date-cell > a {
  color: #6e93b8 !important;
}
.rbc-date-cell > a:visited {
  color: #6e93b8 !important;
  opacity: 0.5;
}
.rbc-event-content .booked {
  background-color: #eb5757;
}
.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: none !important;
}
.rbc-event-content .requested,
.rbc-event-content .pending {
  background-color: #f2994a;
}
.rbc-event-content .available {
  background-color: #49d360;
}

.rbc-event-content .notavailable {
  background-color: #000;
}

.rbc-event-content .popover-height.no {
  color: #000;
}

.userDetailTable {
  table-layout: fixed;
}
.userDetailTable tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.userDetailTable th,
.userDetailTable tr td {
  padding: 0;
  border: 0;
}

.userDetailTable tr td:first-child {
  width: 35%;
}
.userDetailTable tr td:nth-child(2) {
  width: 57%;
}
.userDetailTable tr td:last-child {
  width: 8%;
}

@media only screen and (max-width: 480px) {
  .rbc-day-bg {
    margin: 10px 2px 5px !important;
  }
}

/*dashboard checkbox*/
.checkboxOverride input[type='checkbox'] {
  visibility: hidden;
}
.checkboxOverride {
  margin: 20px 120px;
  position: relative;
  width: 25px;
}
.checkboxOverride input[type='checkbox']:checked + label:after {
  opacity: 1;
}
.checkboxOverride label {
  background: none repeat scroll 0 0 #eeeeee;
  border: 1px solid #dddddd;
  cursor: pointer;
  height: 25px;
  left: 0;
  position: absolute;
  top: 0;
  width: 25px;
}
.checkboxOverride label:after {
  border-style: none none solid solid;
  content: '';
  height: 5px;
  left: 6px;
  opacity: 0;
  position: absolute;
  top: 7px;
  -ms-transform: rotate(-45deg); /* IE 9 */
  -webkit-transform: rotate(-45deg); /* Safari and Chrome */
  transform: rotate(-45deg);
  width: 10px;
}

/*ck editor*/

.ck.ck-list__item .ck-button .ck-button__label,
.ck.ck-icon,
.ck.ck-button .ck-button__label,
a.ck.ck-button .ck-button__label {
  color: #000 !important;
}

.ck.ck-content {
  min-height: 150px;
}

/*new-list-modal*/
.modal-header {
  height: 75px;
  background: #f2f2f2;
  border-radius: 10px 10px 0px 0px;
  padding: 0 15px;
  color: #4b4d67;
  line-height: 75px;
  font-size: 24px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}
.callWrapper {
  width: calc(100% - -40.5px);
  position: relative;
  left: -5.2px;
  top: -1px;
}
.callWrapper a.nav-link {
  height: 49px !important;
  line-height: 35px !important;
  padding-left: 30px;
  padding-right: 30px;
}
.callWrapper .nav-pills .nav-link.active,
.callWrapper .nav-pills .show > .nav-link {
  background-color: #fff;
  height: 49px;
  line-height: 35px;
  color: #4b4d67;
}
.callWrapper .table td,
.table th {
  border: 0;
}
.callWrapper .table td:first-child {
  width: 10%;
}
.callWrapper .table td:nth-child(2) {
  width: 20%;
}
.callWrapper .table td:nth-child(3) {
  width: 15%;
}
.callWrapper .table td:nth-child(4) {
  width: 20%;
}
.callWrapper .table td:nth-child(5) {
  width: 15%;
}
.callWrapper .table td:last-child {
  width: 15%;
}
.serachResutlComp button {
  width: 85px !important;
  min-width: 85px !important;
  height: 30px !important;
  line-height: 15px;
  border-radius: 8px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-right: 15px;
}
.serachResutlComp svg {
  width: 12px !important;
  height: 14px;
}
.serachResutlComp p {
  color: #4b4d67;
  font-size: 16px;
  font-weight: 500;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  padding-right: 10px;
  -ms-flex-line-pack: center;
  align-content: center;
}
.date-input-style {
  color: #44444f;
  width: 100%;
  height: 40px !important;
  line-height: 40px !important;
  -webkit-box-shadow: 4px 4px 10px rgb(69 69 69 / 20%);
  box-shadow: 4px 4px 10px rgb(69 69 69 / 20%);
  border-radius: 8px;
  padding: 15px;
  border: 0;
  font-size: 14px;
  border: 0 !important;
}
.voucherInfoCheckbox .custom-control {
  width: calc(100% / 3);
}
.voucherInfoCheckbox .custom-checkbox .custom-control-label::before {
  border-radius: 2px !important;
}
.voucherInfoCheckbox .custom-control-label::after,
.voucherInfoCheckbox .custom-control-label::before {
  top: 4px;
}

.profileCalendarAfter.requested:after,
.profileCalendarAfter.pending:after {
  background-color: #f2994a;
}
.profileCalendarAfter.booked:after {
  background-color: #eb5757;
}

.profileCalendarAfter.notavailable:after {
  background-color: #333333;
}

.profileCalendarAfter.available:after {
  background-color: #49d360;
}
.skillDialogWrapper img {
  display: inline-block;
  margin-right: 5px;
}
/*loader*/
.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-hourglass:after {
  content: ' ';
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 32px solid #fff;
  border-color: #49d360 transparent #4b4d67 transparent;
  -webkit-animation: lds-hourglass 1.2s infinite;
  animation: lds-hourglass 1.2s infinite;
}

.matrixItem table {
  table-layout: fixed;
  width: 100%;
}

.matrixItem .table td,
.matrixItem .table th {
  border: 1px solid #f2f2f2;
}
.matrixItem .table thead th {
  font-size: 15px;
  font-weight: 600;
  padding: 10px 20px;
}
.matrixItem .table tbody td {
  color: #828282;
  font-weight: 500;
  opacity: 0.8;
  font-size: 15px;
  padding: 5px 20px;
}
.matrixItem .table thead th:first-child,
.matrixItem .table tbody td:first-child {
  width: 25%;
}
.matrixItem .table th:nth-child(2),
.matrixItem .table td:nth-child(2) {
  width: 10%;
}
.matrixItem .table th:nth-child(3),
.matrixItem .table td:nth-child(3) {
  width: 20%;
}
.matrixItem .table th:nth-child(4),
.matrixItem .table td:nth-child(4) {
  width: 20%;
}
.matrixItem .table th:last-child,
.matrixItem .table td:last-child {
  width: 25%;
  padding-left: 15px;
}

@-webkit-keyframes lds-hourglass {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    -webkit-transform: rotate(900deg);
    transform: rotate(900deg);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    -webkit-transform: rotate(1800deg);
    transform: rotate(1800deg);
  }
}

@keyframes lds-hourglass {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    -webkit-transform: rotate(900deg);
    transform: rotate(900deg);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    -webkit-transform: rotate(1800deg);
    transform: rotate(1800deg);
  }
}

/*custom-checkbox*/
.custom-checkbox-wrapper.form-group {
  display: block;
  margin-bottom: 15px;
}

.custom-checkbox-wrapper.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.custom-checkbox-wrapper.form-group label {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  color: #252427;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}

.custom-checkbox-wrapper.form-group label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #e4f0fc;
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 2px;
}

.custom-checkbox-wrapper.form-group input:checked + label:before {
  background-color: #49d360;
}
.custom-checkbox-wrapper.form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  left: 8px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.newSkillSearch {
  height: 45px;
  line-height: 0;
}
.newSkillSearch.form-group {
  margin-bottom: 0 !important;
}
.newSkillSearch .css-yk16xz-control {
  position: relative;
  top: -10px;
  border: 0;
  border-radius: 0;
  background-color: transparent;
}
.newSkillSearch .css-2b097c-container,
.newSkillSearch .css-yk16xz-control {
  height: 45px;
}
.newSkillSearch .css-b8ldur-Input {
  display: none;
}
.newSkillSearch .css-g1d714-ValueContainer img {
  padding-right: 5px;
}
.newSkillSearch .css-1okebmr-indicatorSeparator {
  display: none;
}
.newSkillSearch .css-tlfecz-indicatorContainer {
  padding-right: 0;
  color: #000;
}
.newSkillSearch .css-tlfecz-indicatorContainer:hover {
  color: #000;
}
.newSkillSearch .css-26l3qy-menu {
  width: 200px;
}
/*signup country dropdown*/
.country-dropdown-utils .css-1uccc91-singleValue,
.country-dropdown-utils .PhoneInputInput {
  font-weight: 500;
  color: #b5b5be;
}
.country-dropdown-utils .PhoneInputInput::-webkit-input-placeholder {
  font-weight: 500;
  color: #b5b5be;
  opacity: 0.8;
}
.country-dropdown-utils .PhoneInputInput::-moz-placeholder {
  font-weight: 500;
  color: #b5b5be;
  opacity: 0.8;
}
.country-dropdown-utils .PhoneInputInput:-ms-input-placeholder {
  font-weight: 500;
  color: #b5b5be;
  opacity: 0.8;
}
.country-dropdown-utils .PhoneInputInput::-ms-input-placeholder {
  font-weight: 500;
  color: #b5b5be;
  opacity: 0.8;
}
.country-dropdown-utils .PhoneInputInput::placeholder {
  font-weight: 500;
  color: #b5b5be;
  opacity: 0.8;
}
.country-dropdown-utils .PhoneInputInput {
  font-size: 14px;
  color: #44444f;
}
.signup-country-dropddownWrapper .signup-country-dropdown {
  display: none;
}

.signup-country-dropddownWrapper .css-yk16xz-control,
.signup-country-dropddownWrapper .css-1pahdxg-control {
  font-size: 14px;
  width: 100%;
  border: 1px solid #e2e2ea !important;
  border-radius: 10px;
  min-height: 48px;
  color: #44444f;
  font-family: 'Montserrat', sans-serif;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
}
.signup-country-dropddownWrapper .css-1okebmr-indicatorSeparator {
  display: none;
}
.signup-country-dropddownWrapper .css-2613qy-menu {
  position: relative;
  z-index: 10 !important;
}
.signup-country-dropddownWrapper svg {
  color: #000;
}
.signupPage.signup-country-dropddownWrapper .css-1pahdxg-control {
  border: 1px solid #49d360 !important;
}
.signup-country-dropddownWrapper .signup-country-dropdown-performer {
  position: absolute;
  top: -12px;
  z-index: 1;
  text-align: center;
  background-color: #fff;
  font-weight: 500;
  left: 32px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
  color: #49d360;
  margin-bottom: 0;
}
.SortImageWrapper {
  width: 100%;
}
.bg-performer-dropdown {
  top: -12px !important;
  width: calc(100% - 30px);
  left: 15px !important;
  position: absolute;
  z-index: 1;
  padding-left: 0 !important;
  padding-right: 0 !important;
  background-color: transparent !important;
}
.bg-performer-dropdown span:first-child {
  background-color: #fff;
  width: 35px;
  color: #49d360;
  font-size: 14px;
  top: -25px !important;
  left: 0 !important;
  text-align: left;
  margin-bottom: 0;
  line-height: normal;
}
.bg-performer-dropdown svg {
  width: 16px;
}
.dateComponentResponsive .input-datePicker {
  left: 0;
  top: -25px !important;
  text-align: left;
  background-color: transparent !important;
}
.searchAreaSignup {
  margin-bottom: 15px;
}
.calendar-status-wrapper {
  margin-left: 10px;
  margin-right: 20px;
  color: #828282;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
/*uploadResumeBtn*/
.uploadResumeBtn .dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}
.uploadResumeBtn.dropdown {
  position: relative;
  display: inline-block;
}
.uploadResumeBtn .dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  -webkit-box-shadow: 0 8px 16px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
}
.uploadResumeBtn .dropdown-content a {
  color: black;
  padding: 10px 0;
  text-decoration: none;
  display: block;
}
.uploadResumeBtn .dropdown-content a:hover {
  background-color: #fff;
}
.uploadResumeBtn.dropdown:hover .dropdown-content {
  display: block;
}
.uploadResumeBtn.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
ul.pagination {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 0;
}
.pagination li {
  width: 30px;
  height: 30px;
  background: #f2f2f2;
  border-radius: 10px;
  text-align: center;
  margin-right: 5px;
}
.pagination li.active {
  background: #49d360;
}
.pagination li > a {
  font-size: 12px;
  color: #000 !important;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.pagination li.active > a {
  color: #ffffff !important;
  position: relative;
}
.pagination li.previous a::before {
  content: '';
  background-image: url('./assets/images/left-arrow-icon.png');
  background-size: 8px 8px;
  width: 8px;
  height: 8px;
  display: inline-block;
}
.pagination li.next a::before {
  content: '';
  background-image: url('./assets/images/right-arrow-icon.png');
  background-size: 8px 8px;
  width: 8px;
  height: 8px;
  display: inline-block;
}

.union-green {
  background: #49d360;
}

.union-yellow {
  background: #f2994a;
}

.union-blue {
  background: #56ccf2;
}

.union-grey {
  background: #4b4d67;
}
/*help center accordian*/
.accordion .card-header:after {
  font-family: 'FontAwesome';
  content: '\f068';
  float: right;
}
.accordion .card-header.collapsed:after {
  /* symbol for "collapsed" panels */
  content: '\f067';
}
.newTabWrapper {
  width: 200px;
  position: absolute;
  top: 25px;
  border: 1px solid #dddddd;
  padding-block: 5px;
  background-color: #ffffff;
  border-radius: 5px;
  z-index: 2;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.newTabWrapper p {
  border-bottom: 1px solid #dddddd;
  padding-inline: 10px;
  font-size: 14px;
  padding-block: 5px;
}
.newTabWrapper p:last-child {
  border-bottom: 0;
}
.newTabWrapper p > a {
  height: auto !important;
  line-height: normal !important;
  text-decoration: none;
  color: #333;
  padding-inline: 0;
}
.tabBgLayer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  cursor: default;
}
.estimated-bg-heading span {
  color: #4b4d67;
  font-size: 14px;
}
.MobileResponsiveView .performerSearchWrapper .css-yk16xz-control {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e0e0e0;
}
.MobileResponsiveView .performerSearchWrapper .css-1pahdxg-control {
  height: 40px !important;
  min-height: 40px !important;
  border: 1px solid #e0e0e0 !important;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 10px !important;
}
.ag-cell-label-container span,
span.ag-icon-menu,
.ag-react-container span {
  margin-bottom: 0;
  color: #4b4d67;
}
.ag-menu-option-part.ag-menu-option-icon img {
  width: 15px;
}
/*responsiveness*/
@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .calendar-status-wrapper {
    margin-right: 10px;
  }
}
@media only screen and (max-width: 991px) {
  .web-display-only {
    display: none;
  }
  .mobile-display-only {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .dateComponentResponsive {
    margin-top: 0 !important;
  }
  .bg-performer-dropdown span:first-child {
    background-color: transparent !important;
  }
}

@media only screen and (max-width: 576px) {
  .mob-no-padding {
    padding-left: 0;
    padding-right: 0;
  }
  .bg-performer-dropdown span:first-child {
    background-color: transparent !important;
  }
  .searchAreaSignup {
    margin-bottom: 50px;
  }
  .dateComponentResponsive {
    margin-top: 80px;
  }
  .input-datePicker {
    top: -25px !important;
  }
  .calendarWrapperPage {
    padding: 15px;
  }
  .calendar-status-wrapper {
    font-size: 10px;
    margin-right: 10px;
  }
  .rbc-header span {
    font-size: 9px;
  }
  .rbc-date-cell > a,
  .rbc-ellipsis,
  .rbc-event-label,
  .rbc-row-segment .rbc-event-content,
  .rbc-show-more {
    font-size: 12px;
    width: 30px;
    height: 30px;
  }
  .rbc-date-cell {
    font-size: 12px;
    height: 30px;
    line-height: 30px;
  }
  .rbc-selected,
  .popover-height {
    height: 30px;
    text-align: center;
    line-height: 32px;
  }
  .requestTableWrapperMobile {
    padding-inline: 10px;
  }
  .requestTableWrapperMobile .table tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #f2f2f2;
  }
  .requestTableWrapperMobile .table tr td {
    padding: 10px 0;
  }
  .requestTableWrapperMobile .table tr td:first-child {
    width: 55%;
  }
  .requestTableWrapperMobile .table tr td:nth-child(2) {
    width: 35%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-column-gap: 10px;
    -moz-column-gap: 10px;
    column-gap: 10px;
  }
  .requestTableWrapperMobile .table tr td:last-child {
    width: 10%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .MobileResponsiveView .performerSearchWrapper.activeDropdown .css-1pahdxg-control {
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 0 !important;
  }
  .MobileResponsiveView .performerSearchWrapper.activeDropdown .css-yk16xz-control {
    border: 0;
  }
  .performerSearchWrapper.activeDropdown .css-1uccc91-singleValue {
    font-size: 18px;
  }
  .serachResutlComp button {
    width: 66px !important;
    min-width: 66px !important;
    height: 30px !important;
    line-height: 15px;
    border-radius: 5px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 14px !important;
    margin-right: 0 !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .serachResutlComp .laterText {
    width: 66px !important;
    min-width: 66px !important;
    height: 30px !important;
    line-height: 15px;
    padding-right: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-item-align: end;
    align-self: flex-end;
    background: #f2f2f2;
    border-radius: 5px;
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .serachResutlComp .laterTextNoBg {
    background: white;
  }
  ul.pagination {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .callWrapper .nav-pills .nav-link.active,
  .callWrapper .nav-pills .show > .nav-link {
    height: 33px !important;
    line-height: 16px !important;
  }
  .callWrapper a.nav-link {
    height: 33px !important;
    line-height: 16px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  .callWrapper {
    /* width: calc(100% - 0px); */
    position: relative;
    left: 0;
    top: -1px;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .callWrapper .table .MobileResponsiveView td:first-child {
    width: 60%;
  }
  .callWrapper .table .MobileResponsiveView td:last-child {
    width: 40%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .callTableWrapper .table tr:last-child {
    border-bottom: 0;
  }
}

@media only screen and (max-width: 374px) {
  .calendar-status-wrapper {
    margin-right: 0;
  }
}

.ag-row .ag-cell {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.ag-header-cell {
  background-color: #fff;
}

.row-border-color {
  color: #f2f2f2;
}

.ag-theme-alpine .ag-row .ag-cell {
  border-right-color: #f2f2f2;
  border-right-width: 1px;
  border-right-style: solid;
}

.ag-cell-inline-editing {
  height: 30px;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.matrix-status-confirmed {
  background: green;
}

.matrix-status-pending {
  background: orange;
}
.firstCard {
  background-color: #828282;
  padding: 38px;
  width: 300px;
  height: 100%;
  text-align: center;
  box-shadow: 10px 10px 40px rgb(0 0 0 / 10%);
  border-radius: 5p;
}

a.active {
  background-color: #44cd5c;
  color: white !important;
}
