.date {
  height: 10px;
}
.month {
  padding: 3px;
  height: 10px;
}
.calendericon {
  position: relative;
  bottom: 29px;
  height: 16px;
}

.img {
  width: 47px;
  height: 47px;
}

.dateBoxWrapper {
  width: 40px;
  height: 40px;
  background: #f2f2f2;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.dateBoxWrapper h6 {
  font-size: 12px;
  font-weight: 600;
  color: #000;
  letter-spacing: 1px;
}
.dateBoxWrapper p {
  font-size: 10px;
  font-weight: 500;
  color: #828282;
  line-height: 18px;
}

.dateBoxPopupWrapper {
  background: #ffffff;
  border-radius: 10px;
  padding: 18px;
}

.selectedDate {
  background: #4b4d67 !important;
}

.selectedDate p,
.selectedDate h6 {
  color: #ffffff !important;
}
