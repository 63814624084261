.customSaveBtn,
.customCancelBtn {
  width: 100px;
  height: 40px !important;
  min-width: 100px !important;
  background-color: #49d360;
  border-radius: 10px;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #fff;
  letter-spacing: 0.1px;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
}
.customCancelBtn {
  background-color: #4b4d67 !important;
}
.customBtn {
  /* width: auto; */
  background-color: #49d360 !important;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #696974 !important;
  min-width: 100px;
  height: 40px;
  letter-spacing: 0.1px;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
}
.customBtn:hover,
.customBtn:active {
  background-color: #49d360;
  color: #ffffff !important;
  transition: all 0.3s ease-in-out;
}
.loginBtnWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.loginBtnWrapper div:first-child {
  flex: 0 0 150px;
  max-width: 150px;
}
.loginBtnWrapper div:first-child button {
  /* background-color: #f1f1f5 !important; */
  color: #ffffff !important;
}
.loginBtnWrapper div:last-child {
  flex: 0 0 calc(100% - 170px);
  max-width: calc(100% - 170px);
  margin-left: 80px;
}
button:disabled:hover {
  background-color: #f1f1f5;
  color: #696974 !important;
}

.loginButton {
  background-color: #f1f1f5;
  color: #696974 !important;
}

button:enabled {
  /* background-color: #49d360; */
  color: #ffffff !important;
}
.fileUploadBtn {
  height: 40px;
}
.performerSearchBtn {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}
@media only screen and (max-width: 991px) {
  .loginBtnWrapper {
    flex-direction: column-reverse;
  }
  .loginBtnWrapper div:first-child {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    margin-bottom: 20px;
  }
  .loginBtnWrapper div:last-child {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
  .loginBtnWrapper .customBtn {
    width: 100%;
  }
}
.customBtnDanger {
  background-color: rgba(255, 0, 0, 0.733);
  border-radius: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #696974 !important;
  min-width: 100px;
  height: 40px;
  letter-spacing: 0.1px;
  display: inline-block;
  font-family: 'Montserrat', sans-serif !important;
}
