.settingWrapper {
  max-width: calc(100% - 245px);
  width: 100%;
}
​ .passwordWrapper {
  padding: 20px 97px;
  border-bottom: 1px solid #e0e0e0;
}
​ .password {
  margin-top: 19px;
}
​
/*.input {*/
/*    margin-right: 20px;*/
/*}*/

.settingHeading {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3c3f41;
}
​ .passwordButton {
  background-color: #4b4d67 !important;
  border: 1px solid rgba(169, 183, 199, 0.2);
  box-sizing: border-box;
  border-radius: 6px;
  width: 176px;
  height: 40px;
  font-weight: 500;
}
​ .profileWrapper {
  padding: 20px 97px 40px;
  border-bottom: 1px solid #e0e0e0;
}
​ .profileDetailTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #bdbdbd;
}
​ .profileDetailDesc {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #3c3f41;
  margin-left: 17px;
}
.profileDetailDescMT {
  margin-top: 45px;
}
.profileCEdit {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #3c3f41;
}
.settingInputLabel {
  font-size: 14px;
  font-weight: 500;
  color: #4b4d67;
}
.purchaseHistory {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #3c3f41;
}
​ .purchaseHistory a {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #49d360;
}
​ .saveButton {
  background: #49d360;
  border: 1px solid rgba(169, 183, 199, 0.2);
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  min-width: 140px;
}
​ .accountWrapper {
  padding: 40px 56px 39px 100px;
}
​ .accountHeading {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #ff0510;
}
​ .accountDetail {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #3c3f41;
}
​ .deleteButton {
  background: #eb5757 !important;
  border-radius: 8px !important;
  min-width: 105px;
  font-size: 14px;
  font-weight: 500;
}
​ .firstCard {
  background-color: #828282;
  padding: 38px;
  width: 300px;
  height: 366px;
  text-align: center;
  box-shadow: 10px 10px 40px rgb(0 0 0 / 10%);
  border-radius: 5px;
}
.secondCard {
  width: 300px;
  background-color: #49d360;
}
.thirdCard {
  width: 300px;
  background-color: #4b4d67;
}
.firstCardInner {
  padding-top: 40px;
}
.monthText {
  font-size: 10px;
}
.cardInnerImg {
  position: absolute;
  top: 10px;
  right: 0;
}
.cardImageText {
  position: absolute;
  top: 12px;
  left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardImageText p {
  font-size: 15px;
  font-weight: 500;
}
.horizontalRow {
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
}
ul.listingWrapper {
  padding: 0;
  margin-bottom: 0;
}
ul.listingWrapper li {
  list-style: none;
}
.chooseButton {
  background-color: #2e2e2e !important;
  border: 1px solid rgba(169, 183, 199, 0.2);
  box-sizing: border-box;
  border-radius: 25px;
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  min-width: 120px;
  height: 40px;
}
.customRightMargin {
  position: relative;
  right: -42px;
}
.billingMethodImages img {
  width: 50px;
  margin-right: 10px;
}
