.customInput {
    font-size: 14px;
    padding: 10px 15px 10px 15px;
    display: block;
    width: 100%;
    border: 1px solid #e2e2ea;
    border-radius: 10px;
    height: 48px;
    line-height: 48px !important;
    color: #333;
    background-color: #ffffff;
    font-family: 'Montserrat', sans-serif;
}
.customInput::placeholder {
    color: #B5B5BE;
}
.customInput:focus,
.customInput:focus {
    box-shadow: none;
    border-color: #49d360;
    outline: none;
}
.eyeIcon {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #979797;
}