header {
    height: 70px;
    background-color: #ffffff;
}
.topHeading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
header h1 {
    font-weight: bold;
    font-size: 24px;
    color: #000;
    margin-bottom: 0;
}
header a {
    text-decoration: none;
    color: #000;
}
header a:hover {
    text-decoration: none;
    color: #000;
}
header a:hover span {
    color: #49D360;
}
header h1 a > span {
    display: inline-block;
    font-weight: bold;
    color: #49D360;
}