.showMoreContentHidden {
  max-height: 60px;
  overflow: hidden;
}
.showMoreContent {
  max-height: 100%;
}
.tableColumn {
  width: 400px;
}
.imagePreview {
  width: 35px;
  height: 35px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 4px;
  object-fit: cover;
}
.pencilIcon {
  color: #49d360;
}
.imagePreviewModal {
  width: 50px;
  height: 50px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 4px;
  object-fit: cover;
}

.teamInfo {
  margin-bottom: 10px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #4b4d67;
  /*word-break: break-all;*/
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Montserrat', sans-serif;
}
