body {
  margin: 0;
  font-family: 'Lato', sans-serif;
}

#sidebarmenu {
  text-align: center;
  background-color: black;
  color: white;
}

.sidebar {
  overflow-y: hidden;
  margin: 10px;
  padding: 0;
  margin-top: 20px;
  width: 240px;
  background-color: #f1f1f1;
  position: fixed;
  height: 100%;
  /* border: 0.1px solid black; */
  /* overflow: auto; */
}

.sidebar a {
  display: block;
  color: #000000;
  padding: 10px;
  text-decoration: none;
}

.sidebar a.active {
  background-color: #44cd5c;
  color: white;
}

.sidebar a:hover:not(.active) {
  background-color: #44cd5c;
  color: white;
}

div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 1000px;
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  .sidebar a {
    float: left;
  }

  div.content {
    margin-left: 0;
  }
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}
