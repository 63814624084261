.bgLayer {
  width: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.ContentWrapper {
  max-width: 968px;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 560px;
}

.padding15 {
  padding: 15px;
}
.modalBody {
  padding: 15px 30px;
  max-height: calc(560px - 156px);
  overflow-y: auto;
}
.modalFooter {
  justify-content: flex-start !important;
  padding: 0.75rem 30px;
}
h4 {
  font-size: 20px;
  color: #4b4d67;
}
.SvgIcon {
  font-size: 24px;
}
.createInputLabel {
  font-size: 14px;
  color: #bdbdbd;
  font-family: 'Montserrat', sans-serif;
}
.seriesPlusBtn {
  width: 40px;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
}
.seriesPlusBtn svg {
  font-size: 18px;
}
.buttonText {
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 151px;
  height: 40px;
  color: #ffffff;
  border-radius: 8px;
  letter-spacing: 0.5px;
}
.buttonText svg {
  margin-right: 5px;
}
/*episodeBgColor*/
.episodeBgColor {
  background-color: #f2f2f2;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
}
.noLabel {
  opacity: 0;
}

.userExpandedWrapper {
  width: 100%;
  max-width: 909px;
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(69, 69, 69, 0.2);
  border-radius: 5px;
  padding: 5px 10px 5px 5px;
}

.dateLayout {
  width: 100%;
  height: 40px;
  line-height: 40px;
  box-shadow: 4px 4px 10px rgb(69 69 69 / 20%);
  border-radius: 8px;
  padding-left: 15px;
  padding-top: 2px;
  border: 0;
  font-size: 14px;
  color: #bdbdbd;
  font-family: 'Montserrat', sans-serif;
}

/* Modal Header*/

.modalHeader {
  display: flex;
  align-items: center;
  height: 75px;
  background: #f2f2f2;
  border-radius: 10px 10px 0px 0px;
  padding: 0 30px;
  position: relative;
}

.modelCloseIcon {
  position: absolute;
  right: 30px;
}
.modelCloseIcon svg {
  color: #eb5757;
  font-size: 24px;
  font-weight: normal;
}
.icon {
  font-size: 24px;
  color: #4b4d67;
  font-weight: normal;
}
.headerTitle {
  font-size: 24px;
  color: #4b4d67;
  font-weight: normal;
  font-family: 'Montserrat', sans-serif;
}

/* Modal Header */

.modalContentWrapper {
  max-width: 575px;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 3px -5px 40px rgb(205 205 212 / 10%);
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.modalMaxContentWrapper {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 3px -5px 40px rgb(205 205 212 / 10%);
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.modalBodyWrapper {
  padding: 15px 30px;
  overflow-y: auto;
  max-height: 600px;
}
