/****  floating-Lable style start ****/
.floatingLabel {
  position: relative;
  margin-bottom: 20px;
}
.floatingSelectBox {
  font-size: 14px;
  padding: 10px 15px 10px 15px;
  display: block;
  width: 100%;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  height: 48px;
  line-height: 48px !important;
  color: #44444f;
}

.floatingSelectBox:focus {
  outline: none;
  border-color: #49d360;
}

.selectCountryLabel {
  color: #b5b5be;
  letter-spacing: 0.1px;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 15px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  margin-bottom: 0 !important;
  line-height: 1;
}

.floatingSelectBox:focus ~ .selectCountryLabel,
.floatingSelectBox:not([value='']):valid ~ .selectCountryLabel {
  top: -7px;
  font-size: 14px;
  color: #49d360;
  background-color: #ffffff;
  padding: 0 10px;
}

/****  floating-Lable style end ****/

@media only screen and (max-width: 991px) {
  .floatingSelectBox:focus ~ .selectCountryLabel,
  .floatingSelectBox:not([value='']):valid ~ .selectCountryLabel {
    top: -12px;
    background-color: #e5e5e5;
  }
}
