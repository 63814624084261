.sortingWrapper {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
  border-radius: 10px;
  padding: 25px 0 10px;
  margin-bottom: 40px;
}
.featuresWrapper .mainWrapper .imageItem {
  max-width: calc(100% / 5 - 5px);
}
.mainWrapper {
  display: flex;
  flex-wrap: wrap;
  padding-left: 15px;
  padding-right: 15px;
}

.imageWrapper {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 197px;
  height: 250px;
}

.ImageTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 20px;
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: 2px solid #f2f2f2;
  padding-left: 15px;
  padding-right: 15px;
}
.borderBottomDiv {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  border-bottom: 2px solid #f2f2f2;
  margin-bottom: 30px;
}
.image {
  border: 5px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.fullView {
  position: absolute;
  top: 20px;
  right: 20px;
}
.edit {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.imagePlaceholder {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #49d360;
  width: 100%;
  height: 250px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.imagePlaceholder img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.imagePlaceholder p {
  color: #4b4d67;
}
.popupImage {
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #49d360;
  /* padding: 95px 0 0; */
  margin: 0 auto;
}

.popupTitle {
  color: #000000;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  /* padding: 30px 0 95px 0; */
}

.bgLayer {
  width: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.ContentWrapper {
  max-width: 968px;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  z-index: 2;
}

.modalBody {
  border: 1px dashed #49d360;
  box-sizing: border-box;
  padding: 10px;
}

.imageItem {
  position: relative;
  max-width: calc(100% / 7 - 5px);
  width: 100%;
  margin-bottom: 15px;
  margin-right: 5px;
}

.previewImage {
  max-width: 45%;
  /* display: flex; */
  align-items: center;
  /* text-align: center; */
  /* height: 311px; */
  width: 7.5%;
  /* object-fit: cover; */
}

.delete {
  position: relative;
  right: 30px;
  top: 10px;
  height: 30px;
}

.maindiv {
  overflow-x: scroll;
  padding-bottom: 10px;
}

.chooseFileText {
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  color: #0000ee;
  cursor: pointer;
}
/**/
.imageItemDialogWrapper:focus {
  outline: none;
  box-shadow: none;
}
.imageItemDialog {
  position: relative;
  margin: 0 auto;
  width: 275px;
  margin-bottom: 15px;
  margin-top: 15px;
  height: 313px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.displayNone {
  display: none;
}
.arrowIconsWrapper {
  line-height: 0;
}
.arrowIconsWrapper > img {
  width: 10px;
  height: 10px;
}
